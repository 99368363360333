const Auxiliary = (props) => props.children;

export default Auxiliary;
export const formataCPFCNPJ = function (e) {
    if (!e) { return }
    if (e.length <= 11) {
        e = e.replace(/([^\d])+/gim, '');
        return e.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        var x = e.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        e = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
        return e;
    }
}

// export const formataMoeda = function (valor) {
//     valor = valor.replace(/\D/g, '');
//     valor = valor.replace(/(\d{1,2})$/, ',$1');
//     valor = valor.replace(/(\d)(?=(\d{2})+(?!\d))/g, '$1');
//     if (valor.length < 4) { valor = '00' + valor } else { valor = valor.replace(/^[0]{1}/, ''); }
//     return valor;
// }

export const formataMoeda = function (valor) {

    if (!valor) { return; }

    // Remove caracteres não numéricos, exceto a vírgula
    valor = String(valor).replace(/[^\d,]/g, '');

    // Remove vírgulas adicionais (deixe apenas uma)
    valor = valor.replace(/,/g, '');

    // Adicione uma vírgula antes dos últimos dois dígitos
    if (valor.length > 2) {
        const centavos = valor.slice(-2);
        const parteInteira = valor.slice(0, -2);
        valor = parteInteira + ',' + centavos;
    } else if (valor.length === 2) {
        valor = '0,' + valor;
    } else if (valor.length === 1) {
        valor = '0,0' + valor;
    } else {
        valor = '0,00';
    }

    // Remova os zeros à esquerda, exceto um zero antes da vírgula
    valor = valor.replace(/^0+(?=\d)/, '');

    return valor;
}


export const formataServico = function (valor) {
    valor = valor.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (valor.length > 4) { valor = valor.substring(1) }
    if (valor.length === 0) {
        return '00.00';
    } else if (valor.length === 1) {
        return `00.0${valor}`;
    } else if (valor.length === 2) {
        return `00.${valor}`;
    } else {
        const primeiroParte = valor.slice(0, -2);
        const segundaParte = valor.slice(-2);

        return `${primeiroParte}.${segundaParte}`;
    }
}

export const formataCNPJ = function (cnpj) {
    if (cnpj) {
        var x = cnpj.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        cnpj = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
        return cnpj;
    } else return cnpj;
}

export const formataFone = function (tel) {
    return tel.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1)$2-$3")
}

export const formataCep = function (v) {
    if (!v) { return; }
    v = v.toString().replace(/\D/g, '');
    v = v.replace(/(\d{2})?(\d{3})?(\d{3})/, "$1.$2-$3");
    return v;
}

export const formataData = function (d) {
    return d.slice(0, 2) + "/" + d.slice(2, 4) + "/" + d.slice(4, 8);
}

export const formataInscricao = function (v) {
    // Remove todos os caracteres não numéricos
    const numerosDigitados = v.replace(/\D/g, '');

    // Limita o comprimento máximo a 13 dígitos
    const numeroFormatado = numerosDigitados.slice(0, 13);

    // Aplica a máscara
    // const x = numeroFormatado.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    //  const numeroMascarado = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');

    return numeroFormatado;
}

export const formatarUUID = function (input) {

    if (input === '' || input === undefined) { return '' }
    // Remover todos os caracteres não válidos da entrada
    var entradaFormatada = input.replace(/[^0-9a-fA-F-]/g, "");

    // Limitar a entrada a 36 caracteres (comprimento total de um UUID válido)
    entradaFormatada = entradaFormatada.substring(0, 36);

    // Adicionar hífens nos lugares apropriados
    entradaFormatada = entradaFormatada.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
    if (entradaFormatada === '' || entradaFormatada === undefined) { return '' }
    //entradaFormatada
    if (entradaFormatada !== '' && entradaFormatada !== null && entradaFormatada !== undefined && entradaFormatada.length < 17) {
        console.log(input)
        return entradaFormatada.toLowerCase(); //ex: 7803faf086e080a0
    } else {
        console.log('input')
        return entradaFormatada.toUpperCase();
    }

}

export const onlyNumbers = function (v, max) {
    // Remove todos os caracteres não numéricos
    let numerosDigitados = v.replace(/\D/g, '');
    if (max && numerosDigitados.length >= max) { numerosDigitados = numerosDigitados.substr(0, max); return numerosDigitados }
    return numerosDigitados;
}

export const limpaCPFCNPJ = function (valor) {
    if (!valor) {
        return '';
    }
    return valor.replace(/\D/g, ''); //apenas números
}

export const onlyValidNameCharacters = function (input, max) {
    // Remove todos os caracteres inválidos para nomes pessoais
    let validCharacters = input.replace(/[^A-Za-zÀ-ÿ\s'-]/g, '');

    // Converte a primeira letra de cada palavra para maiúscula e o restante para minúscula
    validCharacters = validCharacters.replace(/\b\w/g, (match) => match.toUpperCase());

    if (max && validCharacters.length >= max) {
        validCharacters = validCharacters.substr(0, max);
    }

    return validCharacters;
};

export const maxChars = function (input, max) {
    if (input === '' || input === undefined) { return '' }
    let characters = input;
    return characters.substr(0, max);;
};

export const returnBandeira = (bandeira) => {
    return {
        'T': "Todas",
        '01': "Visa",
        "02": "Mastercard",
        '03': "American Express",
        '04': "Sorocred",
        '05': "Diners Club",
        '06': "Elo",
        '07': "Hipercard",
        '08': "Aura",
        '09': "Cabal",
        '10': "Alelo",
        '11': "Banes Card",
        '12': "CalCard",
        '13': "Credz",
        '14': "Discove",
        '15': "GoodCard",
        '16': "GreenCard",
        '17': "Hiper",
        '18': "JcB",
        '19': "Mais",
        '20': "MaxVan",
        '21': "Policard",
        '22': "RedeCompras",
        '23': "Sodexo",
        '24': "ValeCard",
        '25': "Verocheque",
        '26': "VR",
        '27': "Ticket",
        '99': "Outros"
    }[bandeira ? bandeira : null]
}

export const returnFormaPG = formaPG => {
    return {
        "01": 'Dinheiro',
        '03': 'Cartão de Crédito',
        '04': 'Cartão de Débito',
        '02': 'Cheque',
        '05': 'Crédito Loja',
        '10': 'Vale Alimentação',
        '11': 'Vale Refeição',
        '12': 'Vale Presente',
        '13': 'Vale Combustível',
        '15': 'Boleto Bancário',
        '90': 'Sem pagamento',
        '99': 'Outros'
    }[formaPG ? formaPG : null]
}

export const returnTipoPrazo = tipo => {
    return {
        '0': 'À vista',
        '1': 'À prazo'
    }[tipo ? tipo : null]
}

export const returnPermiteTroco = troco => {
    return {
        'S': 'Sim',
        'N': 'Não'
    }[troco ? troco : null]
}

export const returnAtivo = ativo => {
    return {
        'S': 'Sim',
        'N': 'Não'
    }[ativo ? ativo : null]

}

export const returnAtivoIndex = ativo => {
    return {
        'T': 'Todos',
        'S': 'Sim',
        'N': 'Não'
    }[ativo ? ativo : null]

}

export const returnServico = servico => {
    return {
        'T': "Todos",
        "S": "Apenas serviços",
        "N": "Apenas produtos"
    }[servico ? servico : null]
}

export const returnTipoPessoa = pessoa => {
    return {
        'J': 'Jurídica',
        'F': 'Física'
    }[pessoa ? pessoa : null]
}

export const returnIeDest = ie => {
    return {
        1: "Contribuinte ICMS",
        2: "Contribuinte isento",
        9: "Não contribuinte"
    }[ie ? ie : null]
}

export const returnProdServ = prodserv => {
    return {
        "S": "Serviço",
        "P": "Produto"
    }[prodserv ? prodserv : null]
}

export const returnCstCsosn = cstcsosnR => {
    return {
        "00": "00 - Tributada integralmente",
        "10": "10 - Tributada e com cobrança do ICMS por substituição tributária",
        "20": "20 - Com redução da BC",
        "30": "30 - Isenta / não tributada e com cobrança do ICMS por substituição tributária",
        "40": "40 - Isenta",
        "41": "41 - Não tributada",
        "50": "50 - Com suspensão",
        "51": "51 - Com diferimento",
        "60": "60 - ICMS cobrado anteriormente por substituição tributária",
        "70": "70 - Com redução da BC e cobrança do ICMS por substituição tributária",
        "90": "90 - Outras (CST)",
        "101": "101 - Tributada pelo Simples Nacional com permissão de crédito", //FIXME: pode não funcionar
        "102": "102 - Tributada pelo Simples Nacional sem permissão de crédito",
        "103": "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta",
        "201": "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
        "202": "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
        "203": "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária ",
        "300": "300 - Imune",
        "400": "400 - Não tributada pelo Simples Nacional",
        "500": "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
        "900": "900 - Outras (CSOSN)",
    }[cstcsosnR ? cstcsosnR : null];
}

export const returnTipoUser = tipo => {
    return {
        "UN": "Usuário Comum",
        "AG": "Administrador Geral"
    }[tipo ? tipo : null]
}

export const regimeEspecialTributacao = [
    { label: 'Microempresa municipal', value: 1 },
    { label: 'Estimativa', value: 2 },
    { label: 'Sociedade de profissionais', value: 3 },
    { label: 'Cooperativa', value: 4 },
    { label: 'Microempresário Individual (MEI)', value: 5 },
    { label: 'Microempresa ou Empresa de Pequeno Porte (ME EPP)', value: 6 },
];

export const returnUF = uf => {
    return {
        'AC': 'Acre',
        'AL': 'Alagoas',
        'AP': 'Amapá',
        'AM': 'Amazonas',
        'BA': 'Bahia',
        'CE': 'Ceará',
        'DF': 'Distrito Federal',
        'ES': 'Espírito Santo',
        'GO': 'Goiás',
        'MA': 'Maranhão',
        'MT': 'Mato Grosso',
        'MS': 'Mato Grosso do Sul',
        'MG': 'Minas Gerais',
        'PA': 'Pará',
        'PB': 'Paraíba',
        'PR': 'Paraná',
        'PE': 'Pernambuco',
        'PI': 'Piauí',
        'RJ': 'Rio de Janeiro',
        'RN': 'Rio Grande do Norte',
        'RS': 'Rio Grande do Sul',
        'RO': 'Rondônia',
        'RR': 'Roraima',
        'SC': 'Santa Catarina',
        'SP': 'São Paulo',
        'SE': 'Sergipe',
        'TO': 'Tocantins'
    }[uf ? uf : null]
}


export const returnAmbiente = ambiente => {
    return {
        '1': 'Produção',
        '2': 'Homologação'
    }[ambiente ? ambiente : null]
}

export const returnRegimeTributario = regime => {
    return {
        '1': 'Simples Nacional',
        '2': 'Simples Nacional - Excesso de sublimite de receita bruta',
        '3': 'Regime Normal'
    }[regime ? regime : null]
}

export const returnTipoDesconto = tipo => {
    return {
        "TT": "Total",
        "IS": "Isento",
        "UN": "Produto"
    }[tipo ? tipo : null]
}

export const returnIndPress = tipo => {
    return {
        "1": "Operação presencial",
        "2": "Operação não presencial, pela Internet",
        "3": "Operação não presencial,Teleatendimento",
        "4": "NFC-e em operação com entrega em domicílio",
        "9": "Operação não presencial, outros"
    }[tipo ? tipo : null]
}

export const returnFimNFE = fim => {
    return {
        "1": "NF-e normal",
        "2": "NF-e complementar",
        "3": "NF-e de ajuste",
        "4": "Devolução de mercadoria",
    }[fim ? fim : null]
}

export const returnIdDest = id => {
    return {
        "1": "Operação interna",
        "2": "Operação interestadual",
        "3": "Operação com exterior",
    }[id ? id : null]
}

export const returnTPNF = tp => {
    return {
        "0": "Entrada",
        "1": "Saída"
    }[tp ? tp : null]
}

export const returnTipoDocumento = tipo => {
    return {
        "NFE": "NFE",
        "NFCE": "NFCE"
    }[tipo ? tipo : null]
}

export const plans = [
    {
        name: "Plano 1",
        value: "10,00",
        period: "Semanal",
        icon: "icon",
        qrCode: "",
        link: "",
        img: require('../assets/images/qrimage.png'),
        alt: '',
        description: 'Plano básico'
    },
    {
        name: "Plano 2",
        value: "20,99",
        period: "Mensal",
        icon: "icon",
        qrCode: "",
        link: "",
        img: require('../assets/images/qrimage.png'),
        alt: '',
        description: 'Plano intermediário'
    },
    {
        name: "Plano 3",
        value: "149,99",
        period: "Diário",
        icon: "icon",
        qrCode: "",
        link: "",
        img: require('../assets/images/qrimage.png'),
        alt: '',
        description: 'Plano avançado'
    },
];

export const tabsLabel = [
    { title: 'INFO NF', index: 0, fieldsError: ['tx_descricao', 'nu_ind_exigibilidade'] },
    { title: 'ICMS', index: 1, fieldsError: ['lo_retido_fonte_icms', 'vr_perc_sbt_fcp_icms', 'tx_desc_motivo_desoneracao_icms', 'vr_perc_sbt_mva_icms', 'lo_subst_tributaria_icms', 'nu_mod_base_calculo_icms', 'tx_motivo_desoneracao_icms', 'vr_perc_diferimento_icms', 'vr_perc_credito_icms', 'nu_cfop', 'nu_cest_csosn', 'nu_mod_base_calculo_sbt_icms'] },
    { title: 'ICMS INTERESTADUAL', index: 2, fieldsError: ['vr_perc_fcp_difal', 'lo_fora_uf', 'vr_perc_uf_origem_difal', 'vr_perc_uf_destino_difal', 'lo_fora_ud', 'vr_perc_fcp', 'nu_cfop_fora_uf_sn'] },
    { title: 'PIS/COFINS/IPI', index: 3, fieldsError: ['nu_cst_cofins', 'tx_cod_enquadramento_ipi', 'lo_usa_ipi', 'vr_perc_ipi', 'vr_reduz_bc_iss_fora_uf', 'vr_perc_red_bc_iss', 'vr_perc_iss', 'nu_cst_ipi', 'nu_cst_pis', 'lo_isento_pis', 'lo_isento_cofins', 'lo_isento_ipi'] },
    // { title: 'ISS', index: 4, fieldsError: [] },
    { title: 'OUTROS', index: 4, fieldsError: ['vr_seguro', 'vr_frete', 'vr_outros'] },
]

export const tabsLabelEmpresa = [
    { title: 'Dados', index: 0 },
    { title: 'Endereço', index: 1 },
    { title: 'Fiscais', index: 2 },

]