import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import { withRouter } from "react-router";

const Routes = ({ match }) =>
       <Switch>
              <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import("./_Dashboard"))} />
              <Route path={`${match.url}/formas_de_pagamento`} component={asyncComponent(() => import("./FormaPagamento"))} />
              <Route path={`${match.url}/natureza_da_operacao`} component={asyncComponent(() => import("./NaturezasOperacao"))} />
              <Route path={`${match.url}/unidade_de_medida`} component={asyncComponent(() => import("./UnidadesMedida"))} />
              <Route path={`${match.url}/produtos_servicos`} component={asyncComponent(() => import("./ProdutosServicos"))} />
              <Route path={`${match.url}/tributacoes`} component={asyncComponent(() => import("./Tributacoes"))} />
              <Route path={`${match.url}/clientes`} component={asyncComponent(() => import("./Clientes"))} />
              <Route path={`${match.url}/transportadoras`} component={asyncComponent(() => import("./Transportadoras"))} />
              <Route path={`${match.url}/configuracoes_nota_fiscal`} component={asyncComponent(() => import("./Configuracoes"))} />
              <Route path={`${match.url}/vendas`} component={asyncComponent(() => import("./Vendas"))} />
              {/* <Route path={`${match.url}/venda/rapida`} component={asyncComponent(() => import("./Vendas"))} /> */}
              <Route path={`${match.url}/fiscal/consulta`} component={asyncComponent(() => import("./Vendas/routes/consultar/index"))} />
              <Route path={`${match.url}/fiscal/cancelamento`} component={asyncComponent(() => import("./Vendas/routes/cancelar/index"))} />
              <Route path={`${match.url}/fiscal/inutilizar`} component={asyncComponent(() => import("./Vendas/routes/inutilizar/index"))} />
              <Route path={`${match.url}/reports`} component={asyncComponent(() => import("./Relatorios/index"))} />
              <Route path={`${match.url}/plans-client`} component={asyncComponent(() => import("./Planos/index"))} />

              <Route path={`${match.url}/parceiro/admin`} component={asyncComponent(() => import("./Parceiro/index"))} />
              <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))} />
       </Switch>;


export default withRouter(Routes);
