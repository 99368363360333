import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IntlMessages from 'util/IntlMessages';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Field, Formik, Form } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import { _dropFormaPG, _getFormaPG, _saveFormaPG, _updateFormaPG } from 'services/formasPg.service';
import { stepOne, stepThree, stepFour } from 'schemas/cadastro.schema';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { formataCep, formataCPFCNPJ, formataCNPJ, formatarUUID, formataFone, onlyNumbers, onlyValidNameCharacters, maxChars } from 'util/Auxiliary';
import { estados, regime_tributario } from 'util/index.data';
import DropZone from '../../../../extensions/routes/dropzone/Dialog/index'
import { _saveUser, _validateMail } from 'services/usuarios.service';
import { _getStatusPagamento, _saveEmpresa } from 'services/empresa.service';
import { ToastContainer, toast } from 'react-toastify';
import { _getCEP } from 'services/cep.service';
import { primary } from 'configs/CustomColors';
import FormDialog from '../../dialogs/formAlerts/FormDialog';
import LoginParceiro from './components/LoginParceiro';
import ModalPagamentoParceiro from './components/ModalPagamento';
import { _auth } from 'services/login.service';
import { useHistory } from 'react-router-dom';
import { SuccessAlert } from '../../alert/sucessRegister';
import { ErrorAlert } from '../../alert/error';
import moment from 'moment';
import { WarningAlert } from '../../alert/warning';

const success = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

const error = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000
  });
}

function retornaValidationStep(activeStep) {
  return {
    "0": stepOne,
    "2": stepThree,
    "3": stepFour
  }[activeStep.toString() ? activeStep.toString() : null]
}


function getSteps() {
  return ["Cadastro de Usuário", "Endereço Pessoal", "Cadastro de Empresa", "Endereço Empresarial", "Documentos Necessários"];
}
//                      { activeStep, values, errors, setFieldValue, setErrors, setBackdrop: this.props.setBackdrop }

function getStepContent({ activeStep, values, errors, setFieldValue, setErrors, setBackdrop }) {
  switch (activeStep) {
    case 0:
      return getUserData(values, errors, setFieldValue, setErrors);
    case 1:
      return getUserAddress(values, errors, setFieldValue, setBackdrop);
    case 2:
      return getBusinessData(values, errors, setFieldValue);
    case 3:
      return getBusinessAddress(values, errors, setFieldValue, setBackdrop);
    case 4:
      return getFileData(values, errors, setFieldValue)

    default:
      return 'Passo desconhecido';
  }
}

//CADASTRO INICIAL
function getUserData(values, errors, setFieldValue, setErrors) {

  const getMailSit = async (email, errors) => {
    await _validateMail({ email })
      .then(response => {
        if (response.data.possui) {
          setErrors({ email: "E-mail já cadastrado" });
        }
      })
  }

  return <div>
    <div className="row">
      <Grid container spacing={3} >
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Nome Completo</InputLabel>
          <Field name="name" variant="outlined" component={TextField} fullWidth
            error={errors.name ? true : false}
            helperText={errors.name}
            onChange={event => setFieldValue('name', onlyValidNameCharacters(event.target.value, 75))}
            value={values.name} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>CPF/CNPJ</InputLabel>
          <Field name="cpf_cnpj" variant="outlined" component={TextField} fullWidth
            error={errors.cpf_cnpj ? true : false}
            helperText={errors.cpf_cnpj}
            onChange={event => setFieldValue('cpf_cnpj', formataCPFCNPJ(event.target.value))}
            value={values.cpf_cnpj} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>E-mail</InputLabel>
          <Field name="email" variant="outlined" component={TextField} fullWidth
            error={errors.email ? true : false}
            onBlur={() => getMailSit(values.email, errors)}
            helperText={errors.email}
            onChange={event => setFieldValue('email', event.target.value.substr(-1) !== ' ' ? maxChars(event.target.value, 50) : values.email)}
            value={values.email} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Senha</InputLabel>
          <Field name="password" variant="outlined" component={TextField} fullWidth
            error={errors.password ? true : false}
            type="password"
            helperText={errors.password}
            onChange={event => setFieldValue('password', maxChars(event.target.value, 20))}
            value={values.password} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Confirmar Senha</InputLabel>
          <Field name="password_confirm" variant="outlined" component={TextField} fullWidth
            error={errors.password_confirm ? true : false}
            type="password"
            helperText={errors.password_confirm}
            onChange={event => setFieldValue('password_confirm', maxChars(event.target.value, 20))}
            value={values.password_confirm} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Telefone</InputLabel>
          <Field name="telefone" variant="outlined"
            component={TextField} fullWidth
            inputProps={{ maxLength: 14 }}
            error={errors.telefone ? true : false}
            helperText={errors.telefone}
            onChange={event => setFieldValue('telefone', maxChars(formataFone(onlyNumbers(event.target.value)), 15))}
            value={values.telefone} />
        </Grid>
      </Grid>
    </div>
  </div>
}

function getUserAddress(values, errors, setFieldValue, backdrop) {

  const getImageContent = (value) => {
    setFieldValue('imagem', value);
  }

  const setFieldCepContent = async (value) => {
    backdrop(true);

    await _getCEP(value)
      .then(response => {
        setFieldValue('u_bairro', response.data.bairro);
        setFieldValue('u_cidade', response.data.cidade);
        setFieldValue('u_endereco', response.data.logradouro);
        setFieldValue('u_uf', response.data.estado);
        backdrop(false);
      })
      .catch(() => {
        backdrop(false);
        error("O CEP digitado não foi encontrado.");
      });
  }

  return <div>
    <div className="row">
      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} >
          <DropZone label="Foto de Perfil" icon="account" setFieldValue={getImageContent} fieldName={'imagem'}
            accept={{
              accept: 'image/jpeg, image/png'
            }} />
        </Grid>
        <Grid item xs={12} sm={2} >
          <InputLabel shrink>CEP</InputLabel>
          <Field name="u_cep" variant="outlined" maxLength={10} component={TextField} fullWidth
            error={errors.u_cep ? true : false}
            helperText={errors.u_cep ? "Verifique os dados fornecidos." : null}
            onChange={event => {

              if (event.target.value.length === 10) {
                setFieldCepContent(event.target.value.replace('.', '').replace('-', ''));
              }
              setFieldValue('u_cep', maxChars(formataCep(event.target.value), 10));
            }}
            value={values.u_cep} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Logradouro</InputLabel>
          <Field name="u_endereco" variant="outlined" component={TextField} fullWidth
            error={errors.u_endereco ? true : false}
            helperText={errors.u_endereco ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_endereco', maxChars(event.target.value, 70))}
            value={values.u_endereco} />
        </Grid>
        <Grid item xs={12} sm={2} >
          <InputLabel shrink>Nº</InputLabel>
          <Field name="u_nro" variant="outlined" component={TextField} fullWidth
            error={errors.u_nro ? true : false}
            helperText={errors.u_nro ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_nro', maxChars(event.target.value, 10))}
            value={values.u_nro} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Complemento</InputLabel>
          <Field name="u_complemento" variant="outlined" component={TextField} fullWidth
            error={errors.u_complemento ? true : false}
            helperText={errors.u_complemento ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_complemento', maxChars(event.target.value, 50))}
            value={values.u_complemento} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Bairro</InputLabel>
          <Field name="u_bairro" variant="outlined" component={TextField} fullWidth
            error={errors.u_bairro ? true : false}
            helperText={errors.u_bairro ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_bairro', maxChars(event.target.value, 50))}
            value={values.u_bairro} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Cidade</InputLabel>
          <Field name="u_cidade" variant="outlined" component={TextField} fullWidth
            error={errors.u_cidade ? true : false}
            helperText={errors.u_cidade ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_cidade', maxChars(event.target.value, 30))}
            value={values.u_cidade} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>UF</InputLabel>
          <Field name="u_uf" variant="outlined" component={Select} fullWidth
            error={errors.u_uf ? true : false} value={values.u_uf}
            helperText={errors.u_uf ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('u_uf', event.target.value)}>
            {estados.map((estado, index) => <MenuItem key={index} value={estado.sigla}>{estado.nome}</MenuItem>)}
          </Field>
        </Grid>
      </Grid>
    </div>
  </div>

}

function getBusinessData(values, errors, setFieldValue) {
  return <div className="tab-pane" id="tab2-3">
    <div className="row">
      <Grid container spacing={3} >
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Razão Social</InputLabel>
          <Field name="razao_social" variant="outlined" component={TextField} fullWidth
            error={errors.razao_social ? true : false}
            helperText={errors.razao_social}
            onChange={event => setFieldValue('razao_social', maxChars(event.target.value, 75))}
            value={values.razao_social} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Nome Fantasia</InputLabel>
          <Field name="nome_fantasia" variant="outlined" component={TextField} fullWidth
            error={errors.nome_fantasia ? true : false}
            helperText={errors.nome_fantasia}
            onChange={event => setFieldValue('nome_fantasia', maxChars(event.target.value, 75))}
            value={values.nome_fantasia} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>CNPJ</InputLabel>
          <Field name="cnpj" variant="outlined" component={TextField} fullWidth
            error={errors.cnpj ? true : false}
            helperText={errors.cnpj}
            onChange={event => setFieldValue('cnpj', formataCNPJ(event.target.value))}
            value={values.cnpj} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Apelido</InputLabel>
          <Field name="apelido" variant="outlined" component={TextField} fullWidth
            error={errors.apelido ? true : false}
            helperText={errors.apelido}
            onChange={event => setFieldValue('apelido', maxChars(event.target.value, 50))}
            value={values.apelido} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Inscrição Estadual</InputLabel>
          <Field name="ie" variant="outlined" component={TextField} fullWidth
            error={errors.ie ? true : false}
            helperText={errors.ie}
            onChange={event => { setFieldValue('ie', onlyNumbers(event.target.value, 15)) }}
            value={values.ie} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Inscrição Municipal</InputLabel>
          <Field name="im" variant="outlined" component={TextField} fullWidth
            error={errors.im ? true : false}
            helperText={errors.im}
            onChange={event => { setFieldValue('im', onlyNumbers(event.target.value, 15)) }}
            value={values.im} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>CNAE</InputLabel>
          <Field name="cnae" variant="outlined" component={TextField} fullWidth
            error={errors.cnae ? true : false}
            helperText={errors.cnae}
            onChange={event => { setFieldValue('cnae', onlyNumbers(event.target.value, 7)) }}
            value={values.cnae} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>E-mail</InputLabel>
          <Field name="email_empresa" variant="outlined" component={TextField} fullWidth
            error={errors.email_empresa ? true : false}
            helperText={errors.email_empresa}
            onChange={event => setFieldValue('email_empresa', maxChars(event.target.value, 50))}
            value={values.email_empresa} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Telefone</InputLabel>
          <Field name="emp_telefone" variant="outlined" component={TextField} fullWidth
            error={errors.emp_telefone ? true : false}
            helperText={errors.emp_telefone}
            onChange={event => setFieldValue('emp_telefone', maxChars(formataFone(onlyNumbers(event.target.value)), 15))}
            value={values.emp_telefone} />
        </Grid>
        <Grid item xs={12} sm={12} >
          <InputLabel shrink>Regime Tributário</InputLabel>
          <Field name="crt" variant="outlined" component={Select} fullWidth
            error={errors.crt ? true : false} value={values.crt}
            helperText={errors.crt}
            onChange={event => setFieldValue('crt', event.target.value)}>
            {regime_tributario.map((crt, index) => <MenuItem key={index} value={crt.id}>{crt.label}</MenuItem>)}
          </Field>
        </Grid>
      </Grid>
    </div>
  </div>
}

function getBusinessAddress(values, errors, setFieldValue, backdrop) {


  const setFieldCepContent = async (value) => {
    backdrop(true);

    await _getCEP(value)
      .then(response => {
        setFieldValue('e_bairro', response.data.bairro);
        setFieldValue('e_cidade', response.data.cidade);
        setFieldValue('e_logradouro', response.data.logradouro);
        setFieldValue('e_uf', response.data.estado);
        setFieldValue('e_cuf', response.data.estado_info.codigo_ibge);
        setFieldValue('e_cmun', response.data.cidade_info.codigo_ibge);
        backdrop(false);
      })
      .catch(() => {
        backdrop(false);
        error("O CEP digitado não foi encontrado.");
      });
  }

  return <div style={{ marginLeft: 50 }}>
    <div className="row">
      <Grid container spacing={3} >
        <Grid item xs={12} sm={2} >
          <InputLabel shrink>CEP</InputLabel>
          <Field name="e_cep" variant="outlined" component={TextField} fullWidth
            error={errors.e_cep ? true : false}
            helperText={errors.e_cep ? "Verifique os dados fornecidos." : null}
            onChange={event => {

              if (event.target.value.length === 10) {
                setFieldCepContent(event.target.value.replace('.', '').replace('-', ''));
              }
              setFieldValue('e_cep', maxChars(formataCep(event.target.value), 10));
            }}
            value={values.e_cep} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Logradouro</InputLabel>
          <Field name="e_logradouro" variant="outlined" component={TextField} fullWidth
            error={errors.e_logradouro ? true : false}
            helperText={errors.e_logradouro ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_logradouro', maxChars(event.target.value, 70))}
            value={values.e_logradouro} />
        </Grid>
        <Grid item xs={12} sm={2} >
          <InputLabel shrink>Nº</InputLabel>
          <Field name="e_nro" variant="outlined" component={TextField} fullWidth
            error={errors.e_nro ? true : false}
            helperText={errors.e_nro ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_nro', maxChars(event.target.value, 10))}
            value={values.e_nro} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Complemento</InputLabel>
          <Field name="e_complemento" variant="outlined" component={TextField} fullWidth
            error={errors.e_complemento ? true : false}
            helperText={errors.e_complemento ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_complemento', maxChars(event.target.value, 30))}
            value={values.e_complemento} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Bairro</InputLabel>
          <Field name="e_bairro" variant="outlined" component={TextField} fullWidth
            error={errors.e_bairro ? true : false}
            helperText={errors.e_bairro ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_bairro', maxChars(event.target.value, 50))}
            value={values.e_bairro} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>Cidade</InputLabel>
          <Field name="e_cidade" variant="outlined" component={TextField} fullWidth
            error={errors.e_cidade ? true : false}
            helperText={errors.e_cidade ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_cidade', maxChars(event.target.value, 30))}
            value={values.e_cidade} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>CMUN - Cód. do Município</InputLabel>
          <Field name="e_cmun" variant="outlined" component={TextField} fullWidth
            error={errors.e_cmun ? true : false}
            helperText={errors.e_cmun ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_cmun', onlyNumbers(event.target.value, 8))}
            value={values.e_cmun} />
        </Grid>
        <Grid item xs={12} sm={4} >
          <InputLabel shrink>CUF - Cód. Unidade Federativa</InputLabel>
          <Field name="e_cuf" variant="outlined" component={TextField} fullWidth
            error={errors.e_cuf ? true : false}
            helperText={errors.e_cuf ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_cuf', onlyNumbers(event.target.value, 2))}
            value={values.e_cuf} />
        </Grid>
        <Grid item xs={12} sm={2} >
          <InputLabel shrink>UF</InputLabel>
          <Field name="e_uf" variant="outlined" component={Select} fullWidth
            error={errors.e_uf ? true : false} value={values.e_uf}
            helperText={errors.e_uf ? "Verifique os dados fornecidos." : null}
            onChange={event => setFieldValue('e_uf', event.target.value)}>
            {estados.map((estado, index) => <MenuItem key={index} value={estado.sigla}>{estado.sigla}</MenuItem>)}
          </Field>
        </Grid>
      </Grid>
    </div>
  </div>
}


function getFileData(values, errors, setFieldValue) {

  const getCertificadoContent = (value) => {
    setFieldValue('certificado', value.split(','));
  }

  const getLogoNFCEContent = (value) => {
    setFieldValue('logo_nfce', value.split(','));
  }

  const getLogoNFEContent = (value) => {
    setFieldValue('logo_nfe', value.split(','));
  }
  return (
    <div>
      <div className="row">
        <Grid container spacing={3} >
          <Grid item xs={12} sm={4} >
            <DropZone label="Certificado Digital" icon="file" setFieldValue={getCertificadoContent} isFile={values.certificado} />
          </Grid>
          <Grid item xs={12} sm={4} >
            <DropZone label="Logo NFC-E" icon="image" setFieldValue={getLogoNFCEContent}
              accept={{
                //accept: 'image/jpeg, image/png'
                accept: 'image/gif'
              }} />
          </Grid>
          <Grid item xs={12} sm={4} >
            <DropZone label="Logo NF-e" icon="image" setFieldValue={getLogoNFEContent}
              accept={{
                //accept: 'image/jpeg, image/png'
                accept: 'image/gif'
              }} />
          </Grid>
          <Grid item xs={12} sm={12} >
            <InputLabel shrink>Senha do Certificado</InputLabel>
            <Field name="senha_certificado" variant="outlined" component={TextField} fullWidth
              error={errors.senha_certificado ? true : false}
              helperText={errors.senha_certificado ? "Verifique os dados fornecidos." : null}
              onChange={event => setFieldValue('senha_certificado', maxChars(event.target.value, 20))}
              value={values.senha_certificado} />
          </Grid>
          <Grid item xs={12} sm={6} >
            <InputLabel shrink>(NFCE) Token de Produção</InputLabel>
            <Field name="token_nfce_pd" variant="outlined" component={TextField} fullWidth
              error={errors.token_nfce_pd ? true : false}
              helperText={errors.token_nfce_pd ? "Verifique os dados fornecidos." : null}
              onChange={event => { if (event.target.value.length <= 32) { setFieldValue('token_nfce_pd', formatarUUID(event.target.value)) } }}
              value={values.token_nfce_pd} />
          </Grid>
          <Grid item xs={12} sm={6} >
            <InputLabel shrink>(NFCE) Token de Homologação</InputLabel>
            <Field name="token_nfce_hm" variant="outlined" component={TextField} fullWidth
              error={errors.token_nfce_hm ? true : false}
              helperText={errors.token_nfce_hm ? "Verifique os dados fornecidos." : null}
              onChange={event => { if (event.target.value.length <= 32) { setFieldValue('token_nfce_hm', formatarUUID(event.target.value)) } }}
              value={values.token_nfce_hm} />
          </Grid>
          <Grid item xs={12} sm={6} >
            <InputLabel shrink>(NFCE) Código do Token de Produção</InputLabel>
            <Field name="cod_token_nfce_pd" variant="outlined" component={TextField} fullWidth
              error={errors.cod_token_nfce_pd ? true : false}
              helperText={errors.cod_token_nfce_pd ? "Verifique os dados fornecidos." : null}
              onChange={event => { if (event.target.value.length <= 6) { setFieldValue('cod_token_nfce_pd', onlyNumbers(event.target.value)) } }}
              value={values.cod_token_nfce_pd} />
          </Grid>
          <Grid item xs={12} sm={6} >
            <InputLabel shrink>(NFCE) Código do Token de Homologação</InputLabel>
            <Field name="cod_token_nfce_hm" variant="outlined" component={TextField} fullWidth
              error={errors.cod_token_nfce_hm ? true : false}
              helperText={errors.cod_token_nfce_hm ? "Verifique os dados fornecidos." : null}
              onChange={event => { if (event.target.value.length <= 6) { setFieldValue('cod_token_nfce_hm', onlyNumbers(event.target.value)) } }}
              value={values.cod_token_nfce_hm} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'end' }}>

            <FormControlLabel
              control={
                <></>
                // <Checkbox
                //   checked={values.checked}
                //   onChange={(event) => setFieldValue('checked', event.target.checked)}
                //   name=""
                //   color="primary"
                // />
              }
              label={<em style={{ fontSize: 12, color: "gray" }}>Ao clicar no botão abaixo você precisará informar seu login de parceiro.</em>}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

class HorizontalLabelPositionBelowStepper extends React.Component {
  state = {
    activeStep: 0,
    headers: null
  };

  handleNext = (values, errors, setFieldValue) => {
    if (this.state.activeStep === 4) {
      // console.log("VALUES");
      setFieldValue('checked', true);
      // console.log(values);
      // this.submit(values, null, setFieldValue);
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  submit = async (values, param, setFieldValue, token) => {

    this.props.setBackdrop(true);
    values.tipo = 'NU';
    values.bairro = values.u_bairro;
    values.cidade = values.u_cidade;
    values.cep = values.u_cep ? values.u_cep.replace('-', '').replace('.', '') : values.u_cep;
    values.complemento = values.u_complemento;
    values.endereco = values.u_endereco;
    values.nro = values.u_nro;
    values.uf = values.u_uf;
    values.cpf_cnpj = values.cpf_cnpj.replace(/[^\d]+/g, "");
    values.telefone = values.telefone ? values.telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('+55', '') : values.telefone;

    await _saveUser(values).then(async (response) => {
      values.id_user = response.data.id;
      if (response.data.status === 202) {
        this.props.setBackdrop(false);
        error("CADASTRO DO USUÁRIO - ", response.data.msg);
        this.setState({ activeStep: 0 });
      } else {

        await _auth({ email: values.email, password: values.password })
          .then(async response => {
            values.cnpj = values.cnpj.replace(".", "").replace(".", '').replace("-", "").replace("/", '');
            values.bairro = values.e_bairro;
            values.cidade = values.e_cidade;
            values.cep = values.e_cep ? values.e_cep.replace('-', '').replace('.', '') : values.e_cep;
            values.complemento = values.e_complemento;
            values.endereco = values.e_logradouro;
            values.logradouro = values.e_logradouro;
            values.cmun = values.e_cmun;
            values.cuf = values.e_cuf;
            values.nro = values.e_nro;
            values.uf = values.e_uf;
            values.telefone = values.emp_telefone ? values.emp_telefone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '').replace('+55', '') : values.emp_telefone;
            values.email = values.email_empresa;
            values.cod_token_nfce_pd = "";
            values.cod_token_nfce_hm = "";
            values.cnae = values.cnae.replace(/[^\d]+/g, '');
            if (values.certificado) { values.certificado = values.certificado[1] }
            if (values.logo_nfe) { values.logo_nfe = `data:image/gif;base64, ${values.logo_nfe[1]}` }
            if (values.logo_nfce) { values.logo_nfce = + `data:image/gif;base64, ${values.logo_nfce[1]}` }
            const headers = { headers: { "Authorization": "Bearer " + response.data.msg } };
            this.setState({ headers: { headers: { "Authorization": "Bearer " + response.data.msg } } })
            await _saveEmpresa(values, headers)
              .then(response => {
                this.props.setBackdrop(false);
                if (param) {
                  setFieldValue('dadosPagamento', true);
                  setFieldValue('token', token);

                } else {
                  success("Cadastros realizados com sucesso. Faça login");
                  this.props.history.push({ pathname: '/signin', state: { open: true, msg: "Cadastros realizados com sucesso. Faça login" } });
                }
              })
              .catch(erro => {

                this.props.setBackdrop(false);
                if (erro.response) {
                  //console.log(erro.response);
                  //console.log('erro aqu²??i ?');
                  error(erro.response.data.msg);
                } else {
                  //console.log(erro);
                  //console.log('erro aqui ?');
                  error("Erro ao comunicar como a API. Atualize a página e tente novamente");
                }
              });
          }).catch(erro => {
            this.props.setBackdrop(false);
            if (erro.response) {
              //console.log('er?????ro aqui ?');
              error(erro.response.data.msg);
            } else {
              //console.log(erro);
              //console.log('erro aqui ?????w');
              error("Erro ao comunicar como a API. Atualize a página e tente novamente");
            }

          });
      }
    })
      .catch(erro => {
        this.props.setBackdrop(false);
        if (erro.response) {
          error(erro.response.data.msg);
        }
        error("Erro ao comunicar como a API. Atualize a página e tente novamente");

      })
  }

  getPaymentStatus = async (values, setFieldValue) => {
    this.props.setBackdrop(true);
    await _getStatusPagamento({ cnpj: values.cnpj.replace(/[^\d]+/g, "") }, this.state.headers)
      .then(response => {
        this.props.setBackdrop(false);
        if (response.data.status_atual !== null) {
          const today = moment().format("YYYY-MM-DD");
          const updated = moment(response.data.status_atual.updated_at).format("YYYY-MM-DD");
          if (today === updated) {
            setFieldValue('success', true);
          } else {
            setFieldValue("warning", true);
          }
        } else {
          setFieldValue("warning", true);
        }
      }).catch(erro => {
        this.props.setBackdrop(false);
        error("Ocorreu um erro ao buscar o status do pagamento. Tente novamente");
      })
  }

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  render() {
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <>
        <ToastContainer />
        <div className="w-100">
          <div style={{ display: "flex", width: "1005", alignItems: 'center', justifyContent: "center" }}>
            <Link to="/signin">
              <IntlMessages id="signUp.alreadyMember" />
            </Link>
          </div>
          <Stepper activeStep={activeStep} alternativeLabel className="horizontal-stepper-linear">
            {steps.map((label, index) => {
              return (
                <Step key={label} className={`horizontal-stepper ${index === activeStep ? 'active' : ''}`}>
                  <StepLabel className="stepperlabel">{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>

            <Formik enableReinitialize={true}
              initialValues={{
                name: "", cpf_cnpj: "", email: '', password: "", password_confirm: "", telefone: "",
                u_bairro: "", u_cep: "", u_cidade: "", u_complemento: "", u_endereco: "", u_nro: "", u_uf: "DF",
                imagem: '',
                razao_social: "", nome_fantasia: "", cnpj: "", apelido: "",
                ie: "", im: "", cnae: "", email_empresa: "",
                emp_telefone: "", crt: '1', ambiente: '1', lo_ativo: 'S',
                e_cep: "", e_logradouro: '', e_bairro: "", e_nro: "", e_complemento: "", e_cidade: "", e_cmun: '', e_uf: "DF", e_cuf: "",
                certificado: '', senha_certificado: '', logo_nfce: '', logo_nfe: '',
                token_nfce_hm: '', token_nfce_pd: '', cod_token_nfce_hm: '', cod_token_nfce_pd: '',
                checked: false, dadosPagamento: false, token: null,
                success: false, error: false, modalMP: false, linkmp: '', warning: false
              }}
              validationSchema={retornaValidationStep(activeStep)}
              onSubmit={(values, errors, setFieldValue) => {
                this.handleNext(values, errors, setFieldValue)
              }}
            >
              {({
                values,
                errors,
                setFieldValue,
                setErrors
              }) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', width: "100%", padding: 15, marginBottom: 30 }}>
                    <Form>
                      {getStepContent({ activeStep, values, errors, setFieldValue, setErrors, setBackdrop: this.props.setBackdrop })}
                      <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: 'space-between', marginTop: 30 }}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={this.handleBack}
                          className="mr-2"
                        >
                          Voltar
                        </Button>
                        {/* {activeStep !== 4 && */}
                        <Button
                          variant="contained" style={{ backgroundColor: Object.keys(errors).length > 0 ? 'gray' : primary, color: "white" }}
                          // type="submit"
                          onClick={() => { this.handleNext(values, errors, setFieldValue) }}
                        >
                          <span>
                            {activeStep === steps.length - 1 ? 'Login como Parceiro' : 'Próximo'}
                          </span>
                        </Button>
                        {/* } */}
                      </div>
                    </Form>
                    {values.checked && <FormDialog maxWidth={'sm'} close={() => { setFieldValue('checked', false); }}
                      locale="Vendas" children={<LoginParceiro error={error} setDadosPagamento={() => setFieldValue('dadosPagamento', true)}
                        close={() => { setFieldValue('checked', false); }} createData={(token) => this.submit(values, "params", setFieldValue, token)} />} />}
                    {values.dadosPagamento && <FormDialog maxWidth={'sm'} close={() => {
                      setFieldValue('dadosPagamento', false);
                    }}
                      locale="Vendas" children={
                        <ModalPagamentoParceiro
                          close={(param) => {
                            setFieldValue('dadosPagamento', false);
                            if (param === 'S') {
                              setFieldValue('success', true);
                            } else {
                              setFieldValue('linkmp', param);
                              setFieldValue('modalMP', true);
                            }
                          }}
                          values={values} />} />}

                    {values.success && <FormDialog title={""} maxWidth={'sm'} success={true}
                      close={() => { setFieldValue('success', false); }} locale="Vendas" nf={true}
                      children={
                        <SuccessAlert message={"Cadastro realizado com sucesso. Pagamento informado. Faça login com o novo usuário para continuar."}
                          close={() => { setFieldValue('success', false); this.props.history.push('/signin'); }} />
                      }
                    />}
                    {values.warning && <FormDialog title={""} maxWidth={'sm'} warning={true}
                      close={() => { setFieldValue('warning', false); }} locale="Vendas" nf={true}
                      children={
                        <WarningAlert message={"Não foi possível encontrar os dados do pagamento. Tente novamente mais tarde."}
                          close={() => { setFieldValue('warning', false); }} />
                      }
                    />}
                    {values.modalMP && <FormDialog title={""} maxWidth={'xl'} vendas={true}
                      close={() => {
                        setFieldValue('modalMP', false);
                        setFieldValue('linkmp', null);
                        this.getPaymentStatus(values, setFieldValue);
                      }} locale="Vendas" nf={false}
                      children={
                        <iframe src={values.linkmp} style={{ width: "100%", height: "100%" }}></iframe>
                      }
                    />}

                  </Box>
                )
              }}
            </Formik >
          </div>
        </div>
      </>
    );
  }
}

export default HorizontalLabelPositionBelowStepper;