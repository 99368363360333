import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "reactstrap";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import moment from 'moment';
import EyeIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CardBox from '../../../../../../components/CardBox/index';
//import NoteIcon from '@material-ui/icons/Note';
import { error, success, warning, secondary } from "configs/CustomColors";

const ListVendas = ({ data, setEdit, venda, emitir }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    const checkOption = (item, index) => {
        setEdit(item, index);
    };
    return (
        <List>
            {data.map((item, index) => {

                return (
                    <CardBox
                        key={item.id}
                        styleName="col-lg-12 col-md-6 col-12"
                        cardStyle="p-0"
                        heading={""}
                        headerOutside
                    >

                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    {/* <CartIcon /> */}
                                </Avatar>
                            </ListItemAvatar>
                            {item.lo_pedido === 'S' ? (
                                <ListItemText style={{ width: "15%" }} className="br-break"
                                    primary={`${item.id}`}
                                    secondary={'Orçamento'} />
                            ) : (
                                <ListItemText style={{ width: "15%" }} className="br-break"
                                    primary={`${item.nu_nota ? `${item.tx_tipo_documento === 'NFSE' ? item.nu_nota + '(RPS)' : item.nu_nota}` : '...'}/${item.nu_serie ? item.nu_serie : '...'}`}
                                    secondary={item.tx_tipo_documento} />
                            )}

                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.vr_total ? "R$" + item.vr_total.replace('.', ',') : null} />
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.dt_evento ? moment(item.dt_evento.slice(0, 10)).format("DD/MM/YYYY") : null} />
                            <ListItemText style={{ width: "15%" }} className="br-break" primary={item.dt_emissao ? moment(item.dt_emissao.slice(0, 10)).format("DD/MM/YYYY") : null} />

                            {item.lo_pedido === 'S' ? (
                                <Badge className="mr-4 mt-2 text-uppercase"
                                    style={{ backgroundColor: secondary, color: "white" }} pill>
                                    Orçamento
                                </Badge>
                            ) : (
                                <Badge className="mr-4 mt-2 text-uppercase"
                                    style={{
                                        backgroundColor: item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000' ? success : item.tx_status_venda_nf === '101' ? error : warning,
                                        color: "white"
                                    }} pill>
                                    {item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000' ? "Nota Emitida" : item.tx_status_venda_nf === '101' ? "Cancelada" : "Não Emitida"}
                                </Badge>
                            )}

                            <ListItemSecondaryAction style={{ flexDirection: 'row' }}>
                                <EyeIcon
                                    color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "action" : "disabled"}
                                    style={{
                                        marginLeft: 5, cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000' ? "pointer" :
                                            "default"
                                    }}
                                    onClick={() => {
                                        if (item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '150' || item.tx_status_venda_nf === 'L000') {
                                            venda(item.id)
                                        }
                                    }} />
                                {item.lo_pedido !== 'S' ? (
                                    <EditIcon style={{ cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "pointer" : "default" }}
                                        color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "disabled" : "action"}
                                        onClick={() => item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '101' ? false : checkOption(item.id, index)}
                                    />
                                ) : ''}

                                {/* <NoteIcon style={{ cursor: item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "pointer" : "default" }}
                                    color={item.tx_status_venda_nf === '101' || item.tx_status_venda_nf === '100' ? "disabled" : "action"}
                                    onClick={() => item.tx_status_venda_nf === '100' || item.tx_status_venda_nf === '101' ? false : emitir(item.id, index)} /> */}

                            </ListItemSecondaryAction>
                        </ListItem>
                    </CardBox>
                );
            })}
        </List>
    );
};

export default ListVendas;
