import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CardBox from '../../../../../../components/CardBox/index';
import FormaPagamento from './ListFormasPagamento';
import Natureza from './ListNaturezas';
import Transportadoras from './ListTransportadoras';
import Unidades from './ListUnidades';
import ProdutoServico from './ListProdutosServicos';
import Tributacao from './ListTributacoes';
import Cliente from './ListClientes';
import Empresa from './ListEmpresas';
import ParceiroUser from './ListParceiroUsers';
import Vendas from './ListVendas';
import Consulta from './ListConsulta';
import Cancelar from './ListCancelar';

const CheckBoxListControl = (props) => {
  const renderComponent = () => {
    switch (props.locale) {
      case 'formapg':
        return <FormaPagamento data={props.data} key={1} alert={props.alert} setEdit={props.setEdit} setForm={props.setForm} />;
      case 'naturezas':
        return <Natureza data={props.data} key={2} alert={props.alert} setEdit={props.setEdit} setForm={props.setForm} />;
      case 'transportadoras':
        return <Transportadoras data={props.data} key={3} alert={props.alert} setEdit={props.setEdit} setForm={props.setForm} />;
      case 'unidades':
        return <Unidades data={props.data} key={4} alert={props.alert} setEdit={props.setEdit} setForm={props.setForm} />;
      case 'prodserv':
        return <ProdutoServico data={props.data} key={5} setForm={props.setForm} alert={props.alert} setEdit={props.setEdit} />;
      case 'tributacoes':
        return <Tributacao data={props.data} key={6} setForm={props.setForm} alert={props.alert} setEdit={props.setEdit} />;
      case 'clientes':
        return <Cliente data={props.data} key={7} setEdit={props.setEdit} setForm={props.setForm} alert={props.alert} />;
      case 'empresas':
        return <Empresa data={props.data} key={8} venda={props.venda} emitir={props.emitir} dispatch={props.dispatch} company={props.company} setForm={props.setForm} alert={props.alert} />;
      case 'parceiro-users':
        return <ParceiroUser data={props.data} key={9} venda={props.venda} emitir={props.emitir} setEdit={props.setEdit} dispatch={props.dispatch} setForm={props.setForm} alert={props.alert} />;
      case 'vendas':
        return <Vendas setEdit={props.setEdit} data={props.data} key={10} venda={props.venda} emitir={props.emitir} />;
      case 'consulta':
        return <Consulta data={props.data} key={11} venda={props.venda} consulta={props.consulta} />;
      case 'cancelar':
        return <Cancelar data={props.data} key={12} venda={props.venda} cancelamento={props.cancelamento} />;
      default:
        return null;
    }
  };

  return (
    <List>
      {props.locale === 'listName' ? (
        <CardBox
          styleName="col-lg-12 col-md-6 col-12"
          cardStyle="p-0"
        >
          <ListItem style={{ justifyContent: "space-between" }}>
            <ListItemAvatar>
              <Avatar />
            </ListItemAvatar>
            <ListItemText style={{ width: "15%", fontWeight: 'bold' }} primary={props.data[0]} />
            <ListItemText style={{ width: "15%", fontWeight: 'bold' }} primary={props.data[1]} />
            <ListItemText style={{ width: "15%", fontWeight: 'bold' }} primary={props.data[2]} />
            <ListItemText style={{ width: "15%", fontWeight: 'bold' }} primary={props.data[3]} />
            <ListItemText style={{ width: "15%", fontWeight: 'bold' }} primary={props.data[4]} />
          </ListItem>
        </CardBox>
      ) : null}
      {renderComponent()}
    </List>
  );
};

export default CheckBoxListControl;