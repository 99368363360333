import { logoImg } from 'configs/CustomColors';
import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";

const SideBarContent = () => {
  let navigationMenus;

  if (sessionStorage.getItem("@Emissor/Type") === "PC") {
    navigationMenus = [
      {
        name: 'sidebar.dashboard.home',
        type: 'item',
        link: '/app/parceiro/admin/dashboard',
        icon: 'home'
      },
      {
        name: 'Usuários',
        type: 'item',
        link: '/app/parceiro/admin/users',
        icon: 'account-box'
      },
      {
        name: 'Empresas',
        type: 'item',
        link: '/app/parceiro/admin/companys',
        icon: 'balance'
      },
      {
        name: 'Pagamentos',
        type: 'item',
        link: '/app/parceiro/admin/payments',
        icon: 'store'
      },

    ];

  } else {

    navigationMenus = [
      {
        name: 'Nova Venda',
        type: 'item',
        link: '/app/dashboard',
        icon: 'home'
      },
      {
        name: 'sidebar.dashboard.vendas',
        type: 'collapse',
        icon: 'card',
        children: [
          {
            name: 'Todas',
            type: 'item',
            link: '/app/vendas',
            icon: 'shopping-cart'
          },
          // {
          //   name: 'sidebar.dashboard.fast',
          //   type: 'item',
          //   link: '/app/venda/rapida',
          //   icon: 'money'
          // },
          {
            name: 'Consultar',
            type: 'item',
            link: '/app/fiscal/consulta',
            icon: 'search'
          },
          {
            name: 'Cancelar',
            type: 'item',
            link: '/app/fiscal/cancelamento',
            icon: 'close-circle'
          },
          {
            name: 'Inutilizar',
            type: 'item',
            link: '/app/fiscal/inutilizar',
            icon: 'alert-triangle'
          }
        ]
      },
      {
        name: 'Produto/Serviço',
        type: 'item',
        link: '/app/produtos_servicos',
        icon: 'check-square'
      },
      {
        name: 'sidebar.dashboard.clientes',
        type: 'item',
        link: '/app/clientes',
        icon: 'account-box'
      },
      {
        name: 'Transportadoras',
        type: 'item',
        link: '/app/transportadoras',
        icon: 'local-shipping'
      },
      {
        name: 'Relatórios',
        type: 'item',
        link: '/app/reports',
        icon: 'file'
      },
      {
        name: "Config. do Sistema",
        type: 'collapse',
        icon: "settings",
        children: [
          {
            name: 'Operação',
            type: 'item',
            link: '/app/natureza_da_operacao',
            icon: 'chart'
          },
          {
            name: 'Un. Medida',
            type: 'item',
            link: '/app/unidade_de_medida',
            icon: 'ruler'
          },
          {
            name: 'Mensalidade',
            type: 'item',
            link: '/app/plans-client',
            icon: 'store'
          },
          {
            name: 'Pagamento',
            type: 'item',
            link: '/app/formas_de_pagamento',
            icon: 'swap-alt'
          },
          {
            name: 'sidebar.dashboard.tributacoes',
            type: 'item',
            link: '/app/tributacoes',
            icon: 'money-off'
          },

          {
            name: 'sidebar.dashboard.config',
            type: 'item',
            link: '/app/configuracoes_nota_fiscal',
            icon: 'device-hub'
          },
        ]
      },

    ];
  }

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars >
  );
};

export default SideBarContent;